import jwtDecode from "jwt-decode";
import {AuthResponseTypes, DecodedTokenType, UserLoginTypes,} from "app/types/UserTypes";
import axios from "./axios.interceptors";
import qs from "querystring";

class AuthService {
  private BASE_PATH = "https://api.prjet.linkplus-it.com";
  handleAuthentication = (): DecodedTokenType | null => {
    const access_token = this.getAccessToken();
    const refresh_token = this.getRefreshToken();

    if (!access_token || !refresh_token) {
      return null;
    }

    const tkn = this.getAccessToken();
    if (tkn) return this.decode(tkn);
    return null;
  };

  signInWithEmailAndPassword = async ({
                                        email,
                                        password,
                                      }: UserLoginTypes): Promise<DecodedTokenType | undefined> => {
    const response = await axios.post(
        "/oauth/token",
        qs.stringify({
          grant_type: "password",
          username: email,
          password: password,
        }),
        {
          headers: {
            Authorization: `Basic ${btoa('b33mt1cket_cl13nt_1d:B3mt16%37-23cr37')}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
    );

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    // Log the API data
    const data: AuthResponseTypes | undefined = await response.data;

    if (data) {
      // @ts-ignore
      return {
        ...this.decode(data.access_token),
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      };
    }

    return undefined;
  };

  setSession = (access_token: string | null, refresh_token: string | null) => {
    if (access_token && refresh_token) {
      localStorage.setItem("jwt_access_token", access_token);
      localStorage.setItem("jwt_refresh_token", refresh_token);
    } else {
      localStorage.removeItem("jwt_access_token");
      localStorage.removeItem("jwt_refresh_token");
    }
  };

  logout = () => {
    this.setSession(null, null);
  };

  decode = (token: string): DecodedTokenType => {
    return jwtDecode(token);
  };

  getAccessToken = () => {
    return localStorage.getItem("jwt_access_token");
  };

  getRefreshToken = () => {
    return localStorage.getItem("jwt_refresh_token");
  };

  refreshTokenService(payload: any) {
    return axios.post("/oauth/token", payload, {
      headers: {
        Authorization: `Basic ${btoa('b33mt1cket_cl13nt_1d:B3mt16%37-23cr37')}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  refresh_token() {
    const token = localStorage.getItem("jwt_refresh_token");
    const requestBody = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: token,
    });

    return new Promise((resolve, reject) => {
      return this.refreshTokenService(requestBody)
      .then((res) => {
        localStorage.setItem("jwt_refresh_token", res.data.refresh_token);
        localStorage.setItem("jwt_access_token", res.data.access_token);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
    });
  }
}

const instance = new AuthService();

export default instance;
